<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('settings.emailNotificationsPreference') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('emails.consentReceiveEmails') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<template>
							<p class="text-h5 mt-4 mb-1">General</p>
							<v-switch v-model="newsletter" label="Newsletter" />
							<v-switch v-model="promotional" :label="$t('emails.promotional')" />
							<v-switch v-model="reminders" :label="$t('emails.reminders')" />
						</template>
						<template>
							<p class="text-h5 mt-4 mb-1">OverSOS Network</p>
							<v-switch v-model="offerChanged" :label="$t('emails.offerChanged')" />
							<v-switch v-model="eventChanged" :label="$t('emails.eventChanged')" />
							<v-switch v-model="newMessage" :label="$t('emails.newMessage')" />
							<v-switch v-model="newCompanies" :label="$t('emails.newCompanies')" />
						</template>
						<template v-if="setupInfo.accountType.student.value">
							<p class="text-h5 mt-4 mb-1">SOS</p>
							<v-switch v-model="exams" :label="$t('emails.exams')" />
							<v-switch v-model="academicYear" :label="$t('emails.academicYear')" />
						</template>
						<template v-if="currentUser.talentPool">
							<p class="text-h5 mt-4 mb-1">Talent Pool</p>
							<p class="text-body-1">
								{{ $t('emails.talentPool') }}
							</p>
						</template>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="callChangeNotifications()">
				{{ $t('settings.update') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeNotificationsForm',
	data() {
		return {
			valid: false,
			promotional: null,
			newsletter: null,
			reminders: null,
			exams: null,
			academicYear: null,
			offerChanged: null,
			eventChanged: null,
			newMessage: null,
			newCompanies: null,
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		let delay = this.currentUser.email != null ? 100 : 1000
		setTimeout(() => {
			this.promotional = this.currentUser.permissions.emails.promotional
			this.newsletter = this.currentUser.permissions.emails.newsletter
			this.reminders = this.currentUser.permissions.emails.reminders
			this.exams = this.currentUser.permissions.emails.exams
			this.academicYear = this.currentUser.permissions.emails.academicYear
			this.offerChanged = this.currentUser.permissions.emails.offerChanged
			this.eventChanged = this.currentUser.permissions.emails.eventChanged
			this.newMessage = this.currentUser.permissions.emails.newMessage
			this.newCompanies = this.currentUser.permissions.emails.newCompanies
		}, delay)
	},
	methods: {
		callChangeNotifications() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeNotifications({
				promotional: this.promotional,
				newsletter: this.newsletter,
				reminders: this.reminders,
				exams: this.exams,
				academicYear: this.academicYear,
				offerChanged: this.offerChanged,
				eventChanged: this.eventChanged,
				newMessage: this.newMessage,
				newCompanies: this.newCompanies,
			})
		},
		...mapActions('settings', ['changeNotifications']),
	},
}
</script>
